import wireframes from '../images/projects/hioi/wireframes.jpg';
import wireframes2 from '../images/projects/hioi/wireframes2.jpg';
import wireframes3 from '../images/projects/hioi/wireframes3.jpg';
import wireframes4 from '../images/projects/hioi/wireframes4.jpg';
import calendar from '../images/projects/hioi/calendar.jpg';
import desktop from '../images/projects/hioi/desktop.jpg';
import timeline from '../images/projects/hioi/timeline.jpg';
import taskManager from '../images/projects/hioi/task_manager.jpg';
import mobileDashboard from '../images/projects/hioi/mobile_dashboard.jpg';
import mobileTasklist from '../images/projects/hioi/mobile_tasklist.jpg';
import mobileDayview from '../images/projects/hioi/mobile_dayview.jpg';
import logoProcess from '../images/projects/hioi/branding/logo_process.gif';
import logoFinal from '../images/projects/hioi/branding/hioi_logo_final.png';
import landingMobile from '../images/projects/hioi/landing_mobile.jpg';
import objects from '../images/projects/hioi/branding/landing_shapes.jpg';

import style1 from '../images/projects/hioi/branding/style1.png';
import style2 from '../images/projects/hioi/branding/style2.png';
import uiKit from '../images/projects/hioi/branding/uikit.png';
import bcards from '../images/projects/hioi/branding/cards.jpg';

import HioiHeadVideo from '../images/projects/hioi/videos/animation_17_07.mp4';
import CalendarDragVideo from '../images/projects/hioi/videos/calendar_drag.mp4';

const HioiPalette = [
  { id: 1, colorCode: '#5969e0', colorName: 'Royal blue' },
  { id: 2, colorCode: '#455eee', colorName: 'Hioi Blue' },
  { id: 3, colorCode: '#d2d8f8', colorName: 'Periwinkle' },
  { id: 4, colorCode: '#a5adc6', colorName: 'Wild blue yonder' },
  { id: 5, colorCode: '#3c4049', colorName: 'Arsenic', isDark: true },
  { id: 6, colorCode: '#17253a', colorName: 'Yankees blue', isDark: true },
  { id: 7, colorCode: '#f5f6fa', colorName: 'Light grey' },
  { id: 8, colorCode: '#ffffff', colorName: 'White' },
];

export const GalleryHioiWireframes = [
  { id: 1, src: wireframes2 },
  { id: 2, src: wireframes3 },
  { id: 3, src: wireframes4 }
];


export const GalleryDesktopUIHioi1 = [
  { id: 1, src: timeline },
  { id: 2, src: taskManager },
  { id: 3, src: calendar }
];

export const GalleryMobileUIHioi = [
  { id: 1, src: mobileDashboard },
  { id: 2, src: mobileTasklist },
  { id: 3, src: mobileDayview },
  { id: 4, isVideo: true, videoSrc: CalendarDragVideo }
];

export const GalleryLogoHioi = [
  { id: 1, src: logoProcess },
  { id: 2, src: logoFinal }
];


export default {
  colorPalette: HioiPalette,
  HeadVideo: HioiHeadVideo,
  projectName: 'Hioi',
  websiteLink: 'https://hioiapp.com/',
  websiteName: 'hioiapp.com',
  projectCategories1:'Visual identity',
  projectCategories2:'UI/UX',
  projectDeliverables1:'Concept, Information Architecture',
  projectDeliverables2:'Design & Development',
  topdesc1: 'Hioi is a new kind of task management platform. It allows users to manage their projects and tasks in an easy, visual way. ',
  topdesc2: 'Plan, organize and track small meetings and huge projects, set deadlines and collaborate with the team. All in one tool.',

  descHeader1:'What is Hioi?',
  descParagraph1:'The idea of Hioi emerges from the need to design a multifunctional, complex tool that will allow the users to easily manage their team, quick tasks and large projects without giving up the aesthetics. Designed to make their work easier, more intuitive and faster.',
  descHeader2:'',
  descParagraph2:'Following our research, we were able to create a customer journey and information architecture. Based on that, we’ve produced wireframes, interactive prototypes and low fidelity sketches.',

  descHeader3:'Style guide',
  descParagraph3:'We have delivered a consistent, modern brand identity and full design guidelines for Hioi. To keep the designs as consistent as possible, along the finished interface designs, we have also produced a document collecting all of the elements.',
  ImageStyle: style1,
  ImageStyle2: style2,
  ImageStyle3: uiKit,
  GalleryLogo: GalleryLogoHioi,
  ImageStyle4: bcards,

  ImageWireframes: wireframes,
  GalleryWireframes: GalleryHioiWireframes,
  descHeader4:'',
  descParagraph4:'The next step was to translate the wireframes and the design system into final designs and think of micro interactions and animations.',
  ImageUI: desktop,
  GalleryUI1: GalleryDesktopUIHioi1,
  GalleryUI2: GalleryMobileUIHioi,

  descHeader5:'Meet Hioi - Website',
  descParagraph5:'The main goal of the first version of the website is to present a new product to the potential customers and build a database of people who may be interested in this tool. These sixteen strange objects, designed and rendered in Blender, are symbols of various tasks and events that the users may encounter. Messy and weird at first, these finally become an organized group of simple, friendly bits and bobs, pleasant to look at. This abstract representation reflects the way the product works - allows the users to easily group and organize upcoming assignments and projects.',
  LandingMobile:landingMobile,
  Objects:objects,
};
