import React from 'react';

import { VideoCaseStudyStyle } from './styles/VideoCaseStudyStyles';

class VideoCaseStudy extends React.PureComponent {
  render() {

    const {
      isRight,
      videoSrc,
      isBackground
    } = this.props;

    return (
      <VideoCaseStudyStyle isRight={isRight} isBackground={isBackground}>
        <video width={'100%'} height={'100%'} autoPlay={true} loop={true} muted={true}>
          <source src={videoSrc} type={'video/mp4'} />
        </video>
      </VideoCaseStudyStyle>
    );
  }
}

export default VideoCaseStudy;
