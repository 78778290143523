import React from 'react';
import { ThemeProvider } from 'styled-components';

import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import AllFixedElements from '../components/AllFixedElements';

import SEO from '../components/seo';

import SectionInfoCaseStudy from '../componentsCaseStudy/SectionInfoCaseStudy';
import SectionImageCaseStudy from '../componentsCaseStudy/SectionImageCaseStudy';
import SectionDescCaseStudy  from '../componentsCaseStudy/SectionDescCaseStudy';
import SectionPaletteCaseStudy from '../componentsCaseStudy/SectionPaletteCaseStudy';
import SectionImagesTiles from '../componentsCaseStudy/SectionImagesTiles';
import SectionLastCaseStudy from '../componentsCaseStudy/SectionLastCaseStudy';
import VideoCaseStudy from '../componentsCaseStudy/VideoCaseStudy';

import SectionFooter from '../components/SectionFooter';
import GlobalStyle from '../components/layoutCss';

import HioiContent from '../data/HioiContent';


class HioiCaseStudy extends React.PureComponent {


  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
        <ThemeContainer>
          <>
            <SEO
              path={this.props.path}
              title='Hioi'
            />
            <AllFixedElements
              isCaseStudy={true}
            />
            <MainContainer>
                <SectionInfoCaseStudy
                  topdesc1={HioiContent.topdesc1}
                  topdesc2={HioiContent.topdesc2}
                  hasWebsite={true}
                  websiteLink={HioiContent.websiteLink}
                  websiteName={HioiContent.websiteName}
                  projectName={HioiContent.projectName}
                  projectCategories1={HioiContent.projectCategories1}
                  projectCategories2={HioiContent.projectCategories2}
                  projectDeliverables1={HioiContent.projectDeliverables1}
                  projectDeliverables2 ={HioiContent.projectDeliverables2}
                />
                <VideoCaseStudy videoSrc={HioiContent.HeadVideo}/>

                {/*----------------------------User experience----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'01'}
                  descHeader={HioiContent.descHeader1}
                  descParagraph={HioiContent.descParagraph1}
                />
                <SectionDescCaseStudy
                  descCounter ={'02'}
                  descHeader={HioiContent.descHeader2}
                  descParagraph={HioiContent.descParagraph2}
                />
                <SectionImagesTiles myArray={HioiContent.GalleryWireframes}/>
                <SectionImageCaseStudy bgImg={HioiContent.ImageWireframes} />

                {/*----------------------------Identity----------------------------*/}


                <SectionDescCaseStudy
                  descCounter ={'03'}
                  descHeader={HioiContent.descHeader3}
                  descParagraph={HioiContent.descParagraph3}
                />
                <SectionPaletteCaseStudy myColorPalette={HioiContent.colorPalette}/>
                <SectionImageCaseStudy bgImg={HioiContent.ImageStyle} />
                <SectionImageCaseStudy bgImg={HioiContent.ImageStyle2} />
                <SectionImageCaseStudy bgImg={HioiContent.ImageStyle3} />
                <SectionImagesTiles myArray={HioiContent.GalleryLogo}/>
                <SectionImageCaseStudy bgImg={HioiContent.ImageStyle4} />


                {/*----------------------------UI----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'04'}
                  descHeader={HioiContent.descHeader4}
                  descParagraph={HioiContent.descParagraph4}
                />
                <SectionImageCaseStudy bgImg={HioiContent.ImageUI} />
                <SectionImagesTiles myArray={HioiContent.GalleryUI1}/>
                <SectionImagesTiles myArray={HioiContent.GalleryUI2}/>

                <SectionDescCaseStudy
                  descCounter ={'06'}
                  descHeader={HioiContent.descHeader5}
                  descParagraph={HioiContent.descParagraph5}
                />
                <SectionImageCaseStudy bgImg={HioiContent.LandingMobile} />
                <SectionImageCaseStudy bgImg={HioiContent.Objects} />
                <SectionLastCaseStudy />
                <SectionFooter />
            </MainContainer>
          </>
        </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}

export default HioiCaseStudy;
