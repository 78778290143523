import styled from "styled-components";

export const VideoCaseStudyStyle = styled.div`
  display:  block;
  position: relative;
  width: 100%;
  transition: 300ms linear;
  opacity: 1;

  /* 1024px */
  @media (min-width: 64em) {
  }
  `;
